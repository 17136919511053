import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import { TitleComponent, HeaderComponent } from 'components';

import * as LeadHousingPlanAction from './leadHousingPlan.Action';
import * as HousingPlanCalculationAction from './leadHousingPlanCalculation.Action';

import './leadHousingPlan.Style.css';
import { leadHousingPlanConstants } from './leadHousingPlan.Constants';

class LeadHousingPlanComponent extends Component {
  constructor(props) {
    super(props);
  }

  handleNextButton(condition) {
    var data = HousingPlanCalculationAction.initialHousingPlanData(this.props.housingPlanData.housingPlanStart, 
                                                                   this.props.housingPlanData.housingPlanDurage, 
                                                                   this.props.selectedHousingFaces, 
                                                                   this.props.products,
                                                                   condition);
    
    HousingPlanCalculationAction.calculatePrices(data);
    this.props.dispatch(LeadHousingPlanAction.storeHousingPlanData(data));

    history.push('/createLead/accessibility');
  }


  render() {
    var jsxToLoad;

    if(this.props.selectedHousingFaces.length !== 0){
      jsxToLoad = <div className="page-wrapper">
      <div className="page-header">
        <HeaderComponent />
      </div>
      <div className="page-content">
        <div className="page-title">
          <TitleComponent title={'De huidige schilderstaat van mijn woning is…'} step={'Stap 3 van 6'}/>
        </div>
        <div className="page-inner-content-default">
          <Grid container className="app-container" direction="row" justify="center" alignItems="center">
            <Grid item container className="housingplan-state-card" onClick={() => this.handleNextButton(leadHousingPlanConstants.NORMAL_CONDITION)}>
              <Grid item xs={12} className="housingplan-state-card-header">
                Goed
              </Grid>
              <Grid item xs={3} md={2} className="housingplan-state-card-logo-wrapper">
                <img src={require('../../../images/happy.png')} alt="Schilderwerk is in goede staat" className="housingplan-state-card-logo" />
              </Grid>
              <Grid item xs={9} md={10}>
                <ul>
                  <li>
                    Weinig scheuren, afbladderingen en barsten.
                  </li>
                  <li>
                    Geen houtrot.
                  </li>
                  <li>
                    Max. 5 jaar geleden geschilderd.
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid item xs={12} container className="housingplan-state-card" onClick={() => this.handleNextButton(leadHousingPlanConstants.MEDIOCRE_CONDITION)}>
              <Grid item xs={12} className="housingplan-state-card-header">
                Matig
              </Grid>
              <Grid item xs={3} md={2} className="housingplan-state-card-logo-wrapper">
                <img src={require('../../../images/neutral.png')} alt="Schilderwerk is in matige staat" className="housingplan-state-card-logo"/>
              </Grid>
              <Grid item xs={9} md={10}>
                <ul>
                  <li>
                    Er zijn wat scheuren, afbladderingen en barsten.
                  </li>
                  <li>
                    Er is kans op houtrot.
                  </li>
                  <li>
                    Het is meer dan ongeveer 6 jaar geleden dat uw woning is geschilderd.
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid item xs={12} container className="housingplan-state-card" onClick={() => this.handleNextButton(leadHousingPlanConstants.BAD_CONDITION)}>
              <Grid item xs={12} className="housingplan-state-card-header">
                Slecht
              </Grid>
              <Grid item xs={3} md={2} className="housingplan-state-card-logo-wrapper">
                <img src={require('../../../images/sad.png')} alt="Schilderwerk is in slechte staat" className="housingplan-state-card-logo"/>
              </Grid>
              <Grid item xs={9} md={10}>
                <ul>
                  <li>
                    Er zijn veel scheuren, afbladderingen en barsten.
                  </li>
                  <li>
                    Er is kans op houtrot.
                  </li>
                  <li>
                    Het is meer dan ongeveer 7 jaar geleden dat er is geschilderd.
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>    
  </div>;
    } else if(this.props.selectedHousingFaces.length === 0) {
      jsxToLoad = <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
              <div className="page-title">
                <TitleComponent title={'Huidige staat schilderwerk'} />
             </div>
             <div className="page-inner-content-default page-inner-content-center-message">
              <p>U heeft geen gevelinformatie opgegeven, ga terug naar de vorige pagina om deze alsnog op te geven.</p>
             </div>
            </div>
      </div>;
    }

    return (
      jsxToLoad  
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedHousingFaces: state.selectedImplementation.filteredHousingFaces,
    housingPlanData: state.housingPlanData,
    products: state.products.products
  }
}

const connectedLeadBestekComponent = connect(mapStateToProps)(LeadHousingPlanComponent);
export { connectedLeadBestekComponent as LeadHousingPlanComponent };