import { responseHandler, sillenceResponseHandler } from 'helpers/responseHandler';
import * as api from 'helpers/api.js';
import { authHeader } from 'helpers/authHeader';

export function processLead(customer, housingPlanData, leadCalculation, filteredHousingFaces, accessibility, leadCalculationTotalMonth) {
    return dispatch => new Promise( function(resolve, reject) {
        var transformedData = transformDataForMailing(customer, housingPlanData, leadCalculation, filteredHousingFaces, accessibility, leadCalculationTotalMonth);

        var auth = authHeader();
        const requestOptions = {
            method: 'POST',
            headers: auth,
            body: JSON.stringify(transformedData)
        };
    
        return fetch(`${api.url()}Mail/SendCustomerAppLead`, requestOptions)
            .then(responseHandler)
            .then(() => {
                resolve();
            })
            .catch(() => {
                reject();                
            });
    });
}

function transformDataForMailing(customer, housingPlanData, leadCalculation, filteredHousingFaces, accessibility, leadCalculationTotalMonth) {
    var transformedData = {};
    transformedData.customer = {
        genderPronoun: customer.personBegin,
        fullName: customer.personInsert ? `${customer.initials} ${customer.personInsert} ${customer.personName}` : `${customer.initials} ${customer.personName}`,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        street: customer.street,
        houseNumber: `${customer.houseNumber}${customer.houseNumberAddition}`,
        zipCode: customer.zipCode,
        city: customer.city
    };

    transformedData.housingPlanData = housingPlanData;

    transformedData.calculation = {
        monthAmount: leadCalculation.monthAmount.toFixed(2),
        onceAmount: leadCalculation.onceAmount.toFixed(2),
        totalAmount: leadCalculation.sumWorkAllIn.toFixed(2),
        totalMonths: leadCalculationTotalMonth
    };

    transformedData.housingFaces = filteredHousingFaces.map(face => {
        return {
            housingFaceName: face.housingFace.name,
            elements: face.elements.map(element => {
                return {
                    housingElementName: element.housingElementName,
                    implementations: element.elementImplementations.filter((impl) => impl.count != 0).map(impl => {
                        if(impl.count != 0){
                            return {
                                description: impl.name,
                                count: impl.count
                            }
                        }
                    })
                };
            })
        };
    });

    transformedData.accessibility = accessibility.accessibilityDescription;

    return transformedData;
}


export function searchAddress(zipCode, houseNumber) {
    if(zipCode && houseNumber && houseNumber > 0){
        return dispatch => {
            var auth = authHeader();
            const requestOptions = {
                method: 'POST',
                headers: auth,
                body: JSON.stringify({
                    zipCode: zipCode,
                    houseNumber: houseNumber
                })
            };
            return fetch(`${api.url()}Customer/SearchStreetByZipCode`, requestOptions).then(sillenceResponseHandler);
        }
    }
}
