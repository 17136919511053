import { accessibilityConstants } from './accessibility.Constants';

const initialState = { 
    accessibilityDescription: '',
    accessibilityAdditionalCosts: 0
}

export const accessibilityReducer = (state, action) => { 
  state = state || initialState;

  switch (action.type)  {
    case accessibilityConstants.STORE_ACCESSIBILITY_DATA: 
        if (action.data) {
            if (action.data === accessibilityConstants.EASY_ACCESSIBILITY) {
                return {
                    ...state,
                    accessibilityDescription: 'Makkelijk te bereiken (ladder/steiger)',
                    accessibilityAdditionalCosts: 250
                }
            } else if (action.data === accessibilityConstants.NORMAL_ACCESSIBILITY) {
                return {
                    ...state,
                    accessibilityDescription: 'Normaal te bereiken (ladder/steiger/hoogwerker)',
                    accessibilityAdditionalCosts: 500
                }
            } else if (action.data === accessibilityConstants.BAD_ACCESSIBILITY) {
                return {
                    ...state,
                    accessibilityDescription: 'Moeilijk te bereiken (ladder/steiger/hoogewerker etc.)',
                    accessibilityAdditionalCosts: 1000
                }
            }
        }       
        return {
        ...state
        }
    default: 
        return state
  }
}
