import React, { Component } from 'react';
import './header.Style.css';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { history } from 'helpers/history';

import * as HeaderAction from './header.Action';

class HeaderComponent extends Component {
  constructor(props) {
    super(props); 
  }

  home() {
    history.push('/home');
  }

  componentDidMount() {
    if(!checkIfUserCanBeOnThisPage(this.props)){
      this.props.dispatch(HeaderAction.reset(true));
    }
  }

  render() { 
    return (
      <div className="app-header">
        <div className="header-content">
          <Grid container className="header-inner-content"  direction="row" alignItems="center">
            <Grid item xs={1}>
            <img src={require('../../../images/home.svg')} onClick={this.home} alt="home" />
            </Grid>
            <Grid item xs={8}>
              <img src={require('../../../images/glansgarant.png')} onClick={this.home} className="logo-header" alt="logo" />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

function checkIfUserCanBeOnThisPage(props) {
  let currentPage = history.location.pathname;
  let result = false;

  switch(currentPage){
    case '/createLead/houseType':
      result = true; // You alway may visit this page
      break;
    case '/createLead/houseFace':
      result = props.housingTypes.selectedHousingTypeId > 0;
      break; 
    case '/createLead/HousingPlan':
      // Same as rules for houseFace, if any elements was selected is handled in the page it self so we cannot check for length here.
      // Because we want a warning over there if none were selected.
      result = props.housingTypes.selectedHousingTypeId > 0; 
      break;
    case '/createLead/accessibility':
      result = props.housingPlanData.length > 0;
      break;
    case '/createLead/calculation':
      result = props.accessibility.accessibilityDescription !== '';
      break;
    case '/createLead/customer':
      result = props.leadCalculation.foundationCalculationData !== '' && props.leadCalculation.foundationCalculationData !== undefined;
      break;
    case '/createLead/finish':
      result = true; // You always may visit this page
      break;
    default:
      result = false;
      break;
  }

  return result;
}

function mapStateToProps(state) {
  return {
    housingTypes: state.housingTypes,
    selectedImplementation: state.selectedImplementation,
    housingPlanData: state.housingPlanData.housingPlanData,
    accessibility: state.accessibility,
    leadCalculation: state.leadCalculation
  };
}

const connected = connect(mapStateToProps)(HeaderComponent);
export { connected as HeaderComponent };
