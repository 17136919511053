import { responseHandler } from 'helpers/responseHandler';
import * as api from 'helpers/api.js';
import { housingTypeConstants } from './housingType.Constants';
import { headerConstants } from '../../../components/layout/header/header.Constants';
import { authHeader } from 'helpers/authHeader';

export function getHousingTypes() {
  return dispatch => {
    var auth = authHeader();
    const requestOptions = {
      method: 'GET',
      headers: auth,
    };

    return fetch(`${api.url()}HousingType/GetHousingTypesCustomerApp`, requestOptions).then(responseHandler)
      .then(data => {
        dispatch({ type: housingTypeConstants.HOUSINGTYPE_GETLIST_SUCCESS, data});
      }).catch(error => {
         dispatch({ type: housingTypeConstants.HOUSINGTYPE_GETLIST_FAIL, error});
      });
  }
}

export function saveSelectedHouseType(housingType) {
  return dispatch => {
    var housingTypeId =  housingType.housingTypeId;
    dispatch({ type: housingTypeConstants.HOUSINGTYPE_SELECTED, housingTypeId });
  }
}