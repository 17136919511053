export function responseHandler(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        alert("U heeft geen rechten voor deze actie");
      } else if (response.status === 429) {
        alert("U heeft teveel aanvragen gedaan. Probeer het over een paar minuten nog een keer");
      } else {
        alert('Er ging iets met het ophalen of opslaan van de data, refresh de pagina en probeer het later nog een keer.');
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

export function sillenceResponseHandler(response){
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        console.error("401 error: ", data);
      } else {
        console.error("Something went wrong: ", data);
      }
 
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}