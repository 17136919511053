import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';

import * as HousingType from './pages/lead/housingType/housingType.Reducer';
import * as Product from './pages/lead/housingType/product/product.Reducer';
import * as HousingFace from './pages/lead/housingFace/housingFace.Reducer';
import * as LeadCalculation from './pages/lead/leadCalculation/leadCalculation.Reducer';
import * as LeadCustomer from './pages/lead/leadCustomer/leadCustomer.Reducer';
import * as HousingPlan from './pages/lead/leadHousingPlan/leadHousingPlan.Reducer';
import * as Accessibility from './pages/lead/accessibility/accessibility.Reducer'

import { headerConstants } from './components/layout/header/header.Constants';

export default function configureStore(history, persistedState) {
  const appReducer = combineReducers({
    housingTypes: HousingType.housingTypeReducer,
    products: Product.productReducer,
    leadCalculation: LeadCalculation.leadCalculationReducer,
    leadCustomer: LeadCustomer.leadCustomerReducer,
    selectedImplementation: HousingFace.housingFaceReducer,
    housingPlanData: HousingPlan.housingPlanReducer,
    accessibility: Accessibility.accessibilityReducer
  });

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

  let enhancer = composeEnhancers(
    applyMiddleware(...middleware)
  );

  const rootReducer = (state,action) => { 
    if (action.type === headerConstants.HARD_RESET) {
      localStorage.removeItem("reduxState");
      return appReducer(undefined, action);
    }
    
    return appReducer(state,action);
  }

  return createStore(
    rootReducer,
    persistedState,
    enhancer
  );
}