import { leadHousingPlanConstants } from './leadHousingPlan.Constants';

const initialState = { 
  housingPlanData: [],
  housingPlanDurage: 10,
  housingPlanStart: (new Date().getFullYear()),
  housingPlanState: 0
}

export const housingPlanReducer = (state, action) => { 
  state = state || initialState;

  switch(action.type)  {
    case leadHousingPlanConstants.STORE_HOUSINGPLANDATA: 
      return {
        ...state,
        housingPlanData: action.data,
      }
    default: 
      return state
  }
}
