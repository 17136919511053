import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TitleComponent, HeaderComponent } from 'components';
import Paper from '@material-ui/core/Paper';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import QuestionBox from '../../../components/questionBox/questionBox.Component';
import { ClipLoader } from 'react-spinners';

import * as HeaderAction from '../../../components/layout/header/header.Action';
import * as LeadCustomerAction from './leadCustomer.Action';

import './leadCustomer.Style.css';

class LeadCustomerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personBegin: '',
      initials: '',
      personInsert: '',
      personName: '',
      email: '',
      phoneNumber: '',
      street: '',
      zipCode: '',
      houseNumber: '',
      houseNumberAddition: '',
      city: '',

      zipCodeChanged: false,

      error: [],
      isLoading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onQuestionBoxClose = this.onQuestionBoxClose.bind(this);
    this.onAddresBlur = this.onAddresBlur.bind(this);
  }

  componentDidMount(){
    if(this.state.isLoading) {
      this.setState({isLoading: false});
    }
  }

  handleBack() {
    history.goBack();
  }

  handleSubmit(e) {
    e.preventDefault();
    
    let isValid = this.validate();
    if(!isValid){
      return;
    }

    const { initials, personBegin, personInsert, personName, email, phoneNumber, street, zipCode, houseNumber, houseNumberAddition, city } = this.state;

    const capitalizeAll = (s) => {
      if (s !== null && typeof s !== 'string') return ''
      return s.toUpperCase()
    }

    const capitalize = (s) => {
      if (s !== null && typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    
    if (personName && street && zipCode && city) {
      var formData = {
        initials: capitalizeAll(initials),
        personBegin: personBegin,
        personInsert: personInsert.trim(),
        personName: capitalize(personName).trim(),
        email: email.trim(),
        phoneNumber: phoneNumber.trim(),
        street: street.trim(),
        zipCode: zipCode.trim(),
        houseNumber: houseNumber.trim(),
        houseNumberAddition: houseNumberAddition.trim(),
        city: city.trim()
      }

      this.setState({ isLoading: true});
      this.props.dispatch(LeadCustomerAction.processLead(formData, this.props.housingPlanData, this.props.leadCalculation, this.props.filteredHousingFaces, this.props.accessibility, this.props.leadCalculationTotalMonth))
        .then(() => { 
          this.props.dispatch(HeaderAction.reset(false));
          history.push('/createLead/finish');
        })
        .catch(() => {
          this.setState({isLoading: false});
          alert("Onze excuses, er is iets misgegaan met het maken van een afspraakverzoek. Probeer het later nog een keer, of bel onze klantenservice vrijblijvend op.");
        });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;

    if(name === "zipCode") {
      this.setState({
        zipCodeChanged: true,
        zipCode: value
      });
    } else {
      this.setState({ [name]: value });
    }
  }

  validate() {
    let missingInformation  = [];
    const { initials, personName, email, phoneNumber, street, zipCode, houseNumber, city } = this.state;
    
    if(!personName) {
      missingInformation.push("Achternaam (verplicht)");
    }

    if(!initials) {
      missingInformation.push("Initialen");
    }

    if(!email) {
      missingInformation.push("E-mailadres");
    }

    if(!phoneNumber) {
      missingInformation.push("(Mobiel)telefoonnummer");
    }

    if(!street) {
      missingInformation.push("Straat");
    }

    if(!zipCode) {
      missingInformation.push("Postcode");
    }

    if(!houseNumber) {
      missingInformation.push("Huisnummer");
    }

    if(!city) {
      missingInformation.push("Plaats");
    }
    if(missingInformation.length != 0){
      this.setState({"error": missingInformation});
      return false;
    }

    return true;
  }

  onQuestionBoxClose() {
    this.setState({"error": []});
  }

  onAddresBlur() {
    if(this.state.zipCode && this.state.houseNumber && !isNaN(this.state.houseNumber) && this.state.houseNumber > 0 && this.state.zipCodeChanged){
      var formattedZipcode = this.state.zipCode.trim().replace(' ', '').toUpperCase();
      var isZipcodeValid = formattedZipcode.match(/[0-9][0-9][0-9][0-9][A-Z][A-Z]/g) && formattedZipcode.length === 6;
      if(isZipcodeValid){
        this.props.dispatch(LeadCustomerAction.searchAddress(formattedZipcode, this.state.houseNumber)).then((data) => {
          this.setState({
            street: data.street,
            city: data.city,
            zipCodeChanged: false
          });
        });
      }
    }
  }

  render() {
    const { error, initials, personBegin, personInsert, personName, email, phoneNumber, street, zipCode, houseNumber, houseNumberAddition, city } = this.state;

    return <div className="page-wrapper">
      <div className="page-header">
        <HeaderComponent />
      </div>
      <div className="page-content">
        <div className="page-title">
          <TitleComponent title={'Mijn contactgegevens'} step={'Stap 6 van 6'}/>
        </div>
        <div className="page-inner-content-default">
          {
            error.length > 0 && 
              <QuestionBox onCancel={this.onQuestionBoxClose} message="Een aantal velden zijn niet (goed) ingevuld?" items={error} title="De volgende gegevens zijn niet ingevuld (!)"></QuestionBox>
          }
          <Grid container spacing={16} className="customer-grid">
            <Grid item xs={12}>
              <form id="customer-form" name="customer-form" onSubmit={this.handleSubmit}>
                <Paper className="customer-paper">
                  <Grid container spacing={16}>
                    <Grid item md={2} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Aanhef</div>
                        <select name="personBegin" className="form-control login-form-input" onChange={this.handleChange} value={personBegin} required>
                          <option value="" disabled defaultValue={true}>Aanhef</option>
                          <option value="M">Heer</option>
                          <option value="V">Mevrouw</option>
                        </select>
                      </div>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Initialen</div>
                        <input type="text" className="form-control login-form-input capitilize-all" name="initials" value={initials} onChange={this.handleChange} autoComplete="off" required/>
                      </div>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Tussenvoegsel</div>
                        <input type="text" className="form-control login-form-input" name="personInsert" value={personInsert} onChange={this.handleChange} autoComplete="off" />
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Achternaam</div>
                        <input type="text" className="form-control login-form-input capitilize-first" name="personName" value={personName} onChange={this.handleChange} autoComplete="off" required/>
                      </div>

                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item md={6} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Telefoonnummer</div>
                        <input type="number" className="form-control login-form-input" name="phoneNumber" value={phoneNumber} onChange={this.handleChange} autoComplete="off" required/>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Email</div>
                        <input type="email" className="form-control login-form-input" name="email" value={email} onChange={this.handleChange} autoComplete="off" required/>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item md={6} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Postcode</div>
                        <input type="text" className="form-control login-form-input" name="zipCode" value={zipCode} onBlur={this.onAddresBlur} onChange={this.handleChange} autoComplete="off" required/>
                      </div>    
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Huisnummer</div>
                        <input type="number" className="form-control login-form-input" name="houseNumber" value={houseNumber || ''} onBlur={this.onAddresBlur} onChange={this.handleChange} autoComplete="off" required/>
                      </div>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Toevoeging</div>
                        <input type="text" className="form-control login-form-input" name="houseNumberAddition" value={houseNumberAddition} onChange={this.handleChange} autoComplete="off" />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item md={6} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Straat</div>
                        <input type="text" className="form-control login-form-input" name="street" value={street || ''} onChange={this.handleChange} autoComplete="off" required/>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Plaats</div>
                        <input type="text" className="form-control login-form-input" name="city" value={city || ''} onChange={this.handleChange} autoComplete="off" required/>
                      </div>

                    </Grid>
                  </Grid>
                  <Grid container spacing={16} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} md={4}>
                      <button type="submit" className="lead-customer-send-button" disabled={this.state.isLoading}> Versturen &nbsp;&nbsp; <ClipLoader sizeUnit={"px"} size={25} color={'white'} loading={this.state.isLoading} /></button>  
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="page-footer">
        <div className="page-footer-inner">
        </div>
      </div>
    </div>
  }
}

function mapStateToProps(state) {
  var leadCalculation = state.leadCalculation.foundationCalculationData;
  return {
    housingPlanData: state.housingPlanData.housingPlanData,
    leadCalculation: leadCalculation,
    leadCalculationTotalMonth: state.leadCalculation.foundationCalculationInput.months,
    filteredHousingFaces: state.selectedImplementation.filteredHousingFaces,
    accessibility: state.accessibility
  }
}


const connected = connect(mapStateToProps)(LeadCustomerComponent);
export { connected as LeadCustomerComponent };

