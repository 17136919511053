import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import { TitleComponent, HeaderComponent } from 'components';
import * as LeadCalculationAction from './leadCalculation.Action';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import LoadingOverlay from 'react-loading-overlay';

import './leadCalculation.Style.css';

class LeadCalculationComponent extends Component {
  constructor(props) { 
    super(props);
  }

  componentDidMount() {
    this.getFoundationCalculation();
  }

  getFoundationCalculation() {
    const { calculation } = this.props;
    var months = calculation && calculation.foundationCalculationInput ? calculation.foundationCalculationInput.months : 0;
    this.props.dispatch(LeadCalculationAction.getFoundationCalculation(this.props.housingPlanData, months, this.props.accessibility.accessibilityAdditionalCosts));
  }

  render() {
    var formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: '0'
    });

    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <LoadingOverlay active={!this.props.calculation.foundationIsLoaded } spinner text='Laden...' className="loader-overlay">
        <div className="page-content">
          <div className="page-title">
            <TitleComponent title={'Mijn investeringsoverzicht'} step={'Stap 5 van 6'}/>
          </div>
          <div className="page-inner-content-calculation">
              {
                this.props.calculation.foundationIsLoaded && 
                <Grid container direction="column" justify="center" alignItems="center" className="calculation-wrapper" spacing={32}>
                  <Grid item xs={12} md={5} className="calculation-explanation">
                    Op basis van de door mij ingevoerde gegevens bedraagt de investering voor de eerste schilderbeurt tussen de <b>{formatter.format(this.props.calculation.foundationCalculationData.onceAmount - 150)}</b> en <b>{formatter.format(this.props.calculation.foundationCalculationData.onceAmount + 150)}</b>.
                    Na de eerste schilderbeurt bedraagt de maandelijkse investering voor de aankomende jaren met daarin de onderstaande werkzaamheden in mijn onderhoudsplan tussen <b>{formatter.format(this.props.calculation.foundationCalculationData.monthAmount - 10)}</b> en <b>{formatter.format(this.props.calculation.foundationCalculationData.monthAmount + 10)}</b> per maand.*    
                  </Grid>
                  <Grid item xs={12} md={5} className="calculation-item">
                    {
                      (this.props.housingPlanData && this.props.housingPlanData.housingPlanData) &&
                        <Table className="calculation-prices-container" style={{ minWidth: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Jaar</TableCell>
                              <TableCell>Type werkzaamheden**</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              this.props.housingPlanData.housingPlanData.map((row, index) => {
                                  return (
                                    <TableRow key={"calculation-" + index}>
                                      <TableCell className="calculation-plan-left-column">{row.year}</TableCell>
                                      <TableCell className="calculation-plan-right-column">{row.selectedProductDescription}</TableCell>
                                    </TableRow>
                                  )
                              })
                            }
                            <TableRow>
                              <TableCell colSpan={2}>
                                  * Het eenmalige en maandbedrag is indicatief. Dit kan dus hoger of lager uitvallen.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2}>
                                  ** Looptijd en de inhoud van de werkzaamheden kan wijzigen.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2}>
                                  *** De door u ingevulde bereikbaarheid kan invloed hebben op de indicatie.
                                </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    }
                  </Grid>  
                  <Grid item xs={12} md={5} className="calculation-item">
                    <div className="calculation-accept-button-container">
                      <button className="calculation-accept-button" onClick={() => history.push('/createLead/customer')}><i>Ik wil graag een afspraak voor een persoonlijke offerte</i></button>
                    </div>
                  </Grid>
                </Grid>
              }            
          </div>
        </div>
        </LoadingOverlay>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    housingPlanData: state.housingPlanData,
    calculation: state.leadCalculation,
    accessibility: state.accessibility
  }
}

const connectedLeadCalculationComponent = connect(mapStateToProps)(LeadCalculationComponent);
export { connectedLeadCalculationComponent as LeadCalculationComponent };
