import { LeadCalculationConstants} from './leadCalculation.Constants';

const initialState = { 
  foundationCalculationData: '',
  foundationCalculationType: '',
  foundationCalculationInput: '',
  
  foundationIsLoaded: false,
  selectedCalculation: ''
}

export const leadCalculationReducer = (state, action) => { 
  state = state || initialState;

  switch(action.type) { 
    case LeadCalculationConstants.REQUEST_FOUNDATION_CALCULATION_SUCCESS: 
    var result = action.data;
    return { 
      ...state,
      foundationCalculationType: result.calculationType,
      foundationCalculationData: result.calculationResult,
      foundationCalculationInput: result.calculationInput,
      isValid: result.isValid,
      foundationIsLoaded: true
    }
    case LeadCalculationConstants.REQUEST_FOUNDATION_CALCULATION_FAIL: {
      return state;
    }
    case LeadCalculationConstants.SELECTED_CALCULATION: { 
      return { 
        ...state,
        selectedCalculation: action.data
      }
    }
    default: 
      return state;
  }
}
