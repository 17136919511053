import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';

import * as HeaderAction from '../../components/layout/header/header.Action';

import './home.Style.css';

class HomeComponent extends Component {
  render() {
    const { dispatch, user } = this.props;

    const startNewLead = () => {
      this.props.dispatch(HeaderAction.reset(false));
      history.push('/createLead/houseType');
    }

    const continueLead = () => {
        history.push('/createLead/houseFace');
    }

    const couldContinue = this.props.selectedElements && Object.keys(this.props.selectedElements).length !== 0;
    return (
      <div className="page-wrapper">
        <div className="page-home-wrapper">
          <Grid container direction="row" justify="center" alignItems="center" className="page-home-container" spacing={8}>
            <Grid item xs={12} md={12} className="page-home-logo">
              <img src={require('../../images/gg_logo.png')} className="page-home-logo" alt="GlansGarant logo"/>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="page-home-subtitle"><h1><i>Het fraaiste onderhoudsplan van Nederland</i></h1></div>
            </Grid>
            <Grid item md={couldContinue ? 3 : 6 } xs={12} className="page-home-button">
              <button onClick={startNewLead} className="page-home-button-element">
                Bereken mijn schilderwerk
              </button>
            </Grid>  
            { couldContinue &&
              <Grid item md={3} xs={12} className="page-home-button">
                <button onClick={continueLead} className="page-home-button-element">
                  Verder gaan...
                </button>
              </Grid>
            }
          </Grid>
        </div>
      </div>  
    )
  }
}

function mapStateToProps(state) {
  let selectedElements;
  if(state.selectedImplementation && state.selectedImplementation.selectedElements){ 
    selectedElements = state.selectedImplementation.selectedElements;
  }
  return {
    selectedElements: selectedElements
  };
}

const connectedHomeComponent = connect(mapStateToProps)(HomeComponent);
export { connectedHomeComponent as HomeComponent };
