import { leadCustomerConstants } from './leadCustomer.Constants';

const initialState = { 
  error: false,
  saved: false
}

export const leadCustomerReducer = (state, action) => { 
  state = state || initialState;

  switch(action.type)  {
    default: 
      return state
  }
}
