export const leadHousingPlanConstants = {
  GETLIST_PRODUCT_SUCCES: 'GETLIST_PRODUCT_SUCCES',
  GETLIST_PRODUCT_FAIL: 'GETLIST_PRODUCT_FAIL',

  STORE_HOUSINGPLANDATA: 'STORE_HOUSINGPLANDATA',

  NORMAL_CONDITION: 100,
  MEDIOCRE_CONDITION: 200,
  BAD_CONDITION: 300
};
