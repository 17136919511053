import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../helpers/history';
import { trackPageView } from '../helpers/TelemetryService';

import { HomeComponent } from '../pages/home/home.Component';
import { HousingTypeComponent } from '../pages/lead/housingType/housingType.Component';
import { HousingFaceComponent } from '../pages/lead/housingFace/housingFace.Component';
import { LeadCalculationComponent } from '../pages/lead/leadCalculation/leadCalculation.Component';
import { LeadCustomerComponent } from '../pages/lead/leadCustomer/leadCustomer.Component';
import { LeadHousingPlanComponent } from '../pages/lead/leadHousingPlan/leadHousingPlan.Component';
import { AccessibilityComponent } from '../pages/lead/accessibility/accessibility.Component';
import { FinishComponent } from '../pages/lead/finish/finish.Component';
import ErrorBoundary from '../components/error/ErrorBoundary';

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    history.listen(location => trackPageView(location.pathname, "history"));
  }

  render() {
    return (
      <ErrorBoundary>
          <Router history={history}>
            <Switch>
              <Route exact path='/home' component={HomeComponent} />
              <Route exact path='/createLead/houseType' component={HousingTypeComponent} />
              <Route exact path='/createLead/houseFace' component={HousingFaceComponent} />
              <Route exact path='/createLead/housingPlan' component={LeadHousingPlanComponent} />
              <Route exact path='/createLead/accessibility' component={AccessibilityComponent} />
              <Route exact path='/createLead/calculation' component={LeadCalculationComponent} />
              <Route exact path='/createLead/customer' component={LeadCustomerComponent} />
              <Route exact path='/createLead/finish' component={FinishComponent} />

              <Route path='*' component={HomeComponent} />
            </Switch>
          </Router>
        </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  return { };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 
