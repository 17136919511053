import { responseHandler } from 'helpers/responseHandler';
import * as api from 'helpers/api.js';
import { authHeader } from 'helpers/authHeader';
import { LeadCalculationConstants } from './leadCalculation.Constants';
import { trackTrace, SeverityLevel } from '../../../helpers/TelemetryService';

export function getFoundationCalculation(data, months, accessibilityCost) {
  return dispatch => {
    if (data) {
      var yearList = [];
      for (var i = 0; i < data.housingPlanData.length; i++) {
        var housingPlanYear = data.housingPlanData[i];
        var price = 0;

        if (housingPlanYear.yearTotalPriceWithCorrection !== "") {
          price = housingPlanYear.yearTotalPriceWithCorrection;
        }
        else {
          price = housingPlanYear.yearTotalPrice;
        }

        var item = {
          year: housingPlanYear.year,
          amount: price
        }

        yearList.push(item);
      }

      var totalMonths = 0;

      if (months > 0) {
        totalMonths = months;
      }
      else {
        totalMonths = (data.housingPlanDurage - 1) * 12;
      }

      var housingPlanData = {
        years: yearList,
        totalMonths: totalMonths,
        accessibilityCost: accessibilityCost
      }

      var auth = authHeader();

      const requestOptions = {
        method: 'POST',
        headers: auth,
        body: JSON.stringify(housingPlanData)
      };

      trackTrace("Calling GetCustomerAppCalculation", SeverityLevel.Information, { requestOptions: requestOptions });

      fetch(`${api.url()}Calculation/GetCustomerAppCalculation`, requestOptions).then(responseHandler)
        .then(data => {
          dispatch({ type: LeadCalculationConstants.REQUEST_FOUNDATION_CALCULATION_SUCCESS, data });
        }).catch(error => {
          dispatch({ type: LeadCalculationConstants.REQUEST_FOUNDATION_CALCULATION_FAIL, error });
        });
    }
  }
}
