const API_URL_DEV = "https://localhost:44379/api/";

export const url = () => {
  if (window.location.hostname.includes("localhost")) {
    return API_URL_DEV;
  } else if (window.location.hostname.includes("glansgaranttest")) {
    var parts = window.location.hostname.split(".");    
    return `${window.location.protocol}//glansgaranttestportalapi.${parts[1]}.${parts[2]}/api/`;
  } else {
    var parts = window.location.hostname.split(".");    
    return `${window.location.protocol}//mobileapi.${parts[1]}.${parts[2]}/api/`;
  }
}
