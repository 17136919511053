import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { history } from 'helpers/history';
import { HousingTypeRowComponent } from './housingTypeRow/housingTypeRow.Component';
import { TitleComponent, HeaderComponent } from 'components';

import * as HeaderAction from '../../../components/layout/header/header.Action';
import * as HousingTypeAction from './housingType.Action';
import * as ProductAction from './product/product.Action';

import './housingType.Style.css';

import LoadingOverlay from 'react-loading-overlay';

class HousingTypeComponent extends Component {
  constructor(props) {
    super(props);

    this.selectedHouseType = this.selectedHouseType.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(HeaderAction.reset(false));
    this.props.dispatch(HousingTypeAction.getHousingTypes(this.props.dispatch));
    this.props.dispatch(ProductAction.listProducts());
  }

  componentDidUpdate() {
    if (this.props.selectedHousingTypeId && this.props.selectedHousingTypeId !== 0) {
      history.push('/createLead/houseFace');
    }
  }

  selectedHouseType = (housingType, e) => {
    const { dispatch } = this.props
    dispatch(HousingTypeAction.saveSelectedHouseType(housingType));
  }

  render() {
    var selectedHousingTypeId = this.props.selectedHousingType;
    var housingTypes = [];

    if (this.props.housingTypes && this.props.housingTypes.length > 0) {
      housingTypes = this.props.housingTypes.map(housingType => {
        return <HousingTypeRowComponent key={housingType.housingTypeId} item={housingType} selected={selectedHousingTypeId} selectedHouseType={this.selectedHouseType.bind(this, housingType)} />
      });
    }
    else
    {
      housingTypes = <p className="page-inner-content-center-message">Er zijn geen woningtypen gevonden die hier weergegeven kunnen worden</p>
    }

    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
          {
            (!this.props.loaded || !this.props.productsLoaded) &&
              <LoadingOverlay active={true} spinner text='Laden...' className="loader-overlay">
                <div className="page-content" />
              </LoadingOverlay>
          }
          {
            (this.props.loaded && !this.props.error && this.props.productsLoaded && !this.props.productsError) && 
              <div className="page-content">
                <div className="page-title">
                  <TitleComponent title={'Mijn type woning is een...'} step={'Stap 1 van 6'}/>
                </div>
                <div className="page-inner-content-default"> 
                  <Grid container spacing={16} direction="row" justify="center">
                    {housingTypes}
                  </Grid>
              </div>
            </div>      
          }
          {
             (this.props.error || this.props.productsError) &&
             <div className="page-content">
               <div className="page-title">
                 <TitleComponent title={'Selecteer type huis'} />
               </div>
               <div className="page-inner-content-default">
                <p className="page-inner-content-center-message">
                  Er is iets misgegaan bij het ophalen van de woningtypen en/of -gevels. Klik op 'Terug' om terug te gaan om het vervolgens opnieuw te proberen.
                </p>
               </div>
             </div>
          }
      </div>
    )
  }
}

function mapStateToProps(state) {
  if(state.housingTypes && state.products){
    return {
      housingTypes: state.housingTypes.data,
      error: state.housingTypes.error,
      loaded: state.housingTypes.loaded,
      selectedHousingTypeId: state.housingTypes.selectedHousingTypeId,
      productsLoaded: state.products.loaded,
      productsError: state.products.error
    }
  }
  return {}
}

const connectedHousingTypeComponent = connect(mapStateToProps)(HousingTypeComponent);
export { connectedHousingTypeComponent as HousingTypeComponent };
