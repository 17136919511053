import { history } from 'helpers/history';
import {headerConstants} from './header.Constants';

export function reset(redirect) {
    return dispatch => {
        localStorage.removeItem('reduxState');
        dispatch({ type: headerConstants.HARD_RESET });
        
        if(redirect){
            history.push('/home');
        }        
    }
}