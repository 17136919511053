import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import './implementation.Style.css';

export class Implementation extends Component {
  render() {
    var minClassName = this.props.count > 0 ? "button-min" : "button-min-disabled";

    return (
      <div>
        <Grid container>
          <Grid item xs={3}>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Grid item>
                <button className={minClassName} onClick={() => this.props.handleMin(this.props.housingElementImplementationId)}> - </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}> 
            <Grid container className="count-wrapper" direction="row" justify="center" alignItems="center">
              <div className="count"> {this.props.count} </div>    
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <button className="button-plus" onClick={() => this.props.handleAdd(this.props.housingElementImplementationId)}> + </button>
            </Grid>
        </Grid>
      </div>
    )
  }
}


