import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import { TitleComponent, HeaderComponent } from 'components';

import * as AccessibilityAction from './accessibility.Action';

import './accessibility.Style.css';
import { accessibilityConstants } from './accessibility.Constants';

class AccessibilityComponent extends Component {
  constructor(props) {
    super(props);
  }

  handleNextButton(accessibility) {
    this.props.dispatch(AccessibilityAction.storeAccessibilityData(accessibility));
    history.push('/createLead/calculation');
  }

  render() {
    return <div className="page-wrapper">
      <div className="page-header">
        <HeaderComponent />
      </div>
      <div className="page-content">
        <div className="page-title">
          <TitleComponent title={'De bereikbaarheid van mijn woning is...'} step={'Stap 4 van 6'}/>
        </div>
        <div className="page-inner-content-default">
          <Grid container className="accessibility-container" direction="row" justify="center" alignItems="center">
            <Grid item container className="accessibility-state-card" onClick={() => this.handleNextButton(accessibilityConstants.EASY_ACCESSIBILITY)}>
              <Grid item xs={12} className="accessibility-state-card-header">
                Goed
              </Grid>
              <Grid item xs={9} md={10}>
                <ul>
                    <li>
                        Er zijn maximaal 2 woonlagen.
                    </li>
                    <li>
                        Alle zijden van de woning zijn makkelijk te bereiken.
                    </li>
                    <li>
                        Er is geen dakkapel.
                    </li>
                </ul>
              </Grid>
              <Grid item xs={3} md={2} className="accessibility-state-card-logo-wrapper">
                <img src={require('../../../images/steiger.png')} alt="Schilderwerk is in goede staat" className="accessibility-state-card-logo" />
              </Grid>
            </Grid>
            <Grid item xs={12} container className="accessibility-state-card" onClick={() => this.handleNextButton(accessibilityConstants.NORMAL_ACCESSIBILITY)}>
              <Grid item xs={12} className="accessibility-state-card-header">
                Matig
              </Grid>
              <Grid item xs={9} md={10}>
                <ul>
                    <li>
                        Er zijn 2 of meer woonlagen.
                    </li>
                    <li>
                        Alle zijden van de woning zijn makkelijk te bereiken.
                    </li>
                    <li>
                        Er is geen/wel (een) dakkapel.
                    </li>
                </ul>
              </Grid>
              <Grid item xs={3} md={2} className="accessibility-state-card-logo-wrapper">
                <img src={require('../../../images/schaarhoogwerker.png')} alt="Schilderwerk is in matige staat" className="accessibility-state-card-logo"/>
              </Grid>
            </Grid>
            <Grid item xs={12} container className="accessibility-state-card" onClick={() => this.handleNextButton(accessibilityConstants.BAD_ACCESSIBILITY)}>
              <Grid item xs={12} className="accessibility-state-card-header">
                Slecht
              </Grid>
              <Grid item xs={9} md={10}>
                <ul>
                    <li>
                        Er zijn 2 of meer woonlagen.
                    </li>
                    <li>
                        Niet elke zijde van de woning is makkelijk te bereiken.
                    </li>
                    <li>
                        Er is geen/wel (een) dakkapel.
                    </li>
                </ul>
              </Grid>
              <Grid item xs={3} md={2} className="accessibility-state-card-logo-wrapper">
                <img src={require('../../../images/hoogwerker.png')} alt="Schilderwerk is in slechte staat" className="accessibility-state-card-logo"/>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>    
  </div>;
  }
}

function mapStateToProps(state) {
  return {
    
  }
}

const connectedAccessibilityComponent = connect(mapStateToProps)(AccessibilityComponent);
export { connectedAccessibilityComponent as AccessibilityComponent };